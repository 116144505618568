import axios from 'axios';
// 创建axios实例
const service = axios.create({
  // baseURL: 'http://10.131.13.233:9070/api', //外网地址
  baseURL: 'https://www.gemdalesports.com/api', //外网地址
  // baseURL: '/api', //外网地址
  timeout: 100000, // 请求超时时间
  /***如果qs没有stringify,将下面方法打开才能提交form,只在post时生效 **/
  /*
  transformRequest: [function (data) {
    // Do whatever you want to transform the data1
    let ret = ''
    for (let it in data) {
      ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
    }
    //console.log(ret);
    return ret;
  }]*/
});

// request拦截器
service.interceptors.request.use(config => {
  //	if(typeof(config.params)=='object'){
  //		config.params['_rnd']=Math.random();
  //	}else{
  //		config.params={
  //			_rnd:Math.random()
  //		}
  //	}
  //GetTypePlayDetailList
  /*if(config.url!='/User/Get'&&config.url.indexOf("GetBetList")==-1&&config.url.indexOf("GetActionBetData")==-1&&config.url.indexOf("GetBetDataList")==-1&&config.url.indexOf("GetBetDataListTop")==-1&&config.url.indexOf("GetUnreadMessage")==-1&&config.url.indexOf("GetLastResult")==-1&&config.url.indexOf("GetCash")==-1){
  	Vue.$vux.loading.show({text: ' '});
  }*/
  // let token = sessionStorage.getItem( 'token' );
  // if ( token ) {
  // 	config.headers[ 'Authorization' ] = token; // 让每个请求携带token--['Authorization']为自定义key 请根据实际情况自行修改
  // }
  config.headers['Content-Type'] = "application/json"; //让每个请求携带Openid,请根据实际情况自行修改
  return config;
}, error => {
  //store.commit('openToast','请求错误');
  //console.log("request_error")
  // Do something with request error
  //console.log(error); // for debug
  Promise.reject(error);
})
/**=======================================**/
// respone拦截器
service.interceptors.response.use(
  /**
   * 下面的注释为通过response自定义code来标示请求状态，当code返回如下情况为权限有问题，登出并返回到登录页
   * 如通过xmlhttprequest 状态码标识 逻辑可写在下面error中,
   */
  response => {
    //Vue.$vux.loading.hide();
    const res = response.data;
    // return res;

    if (res.code === 0) {
      if (response.data.data) {
        return response.data.data;
      } else {
        return true
      }
    } else {
      // Vue.$message.error(res.msg)
      return false;
    }
  },
  /**接口交互错误是处理,如果特殊错误,可对message进行修改**/
  error => {
    //Vue.$vux.loading.hide();
    //Vue.$vux.toast.text("网络错误");
    //store.state.netError = true;
    //store.commit('timeOpen', "<em style='color:red;'>网络故障</em>");
    // console.log( error.response );
    // let msg = '';
    // if ( error.response.status == 502 ) {
    // 	msg = '请重新请求一次'
    // } else {
    // 	for ( let item in error.response.data ) {
    // 		msg = error.response.data[ item ]
    // 	}
    // }
    //
    // store.commit( 'openInfoPop', msg )
    return Promise.reject(error);
  }
)

export default service;
