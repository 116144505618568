import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

//测试页面
//首页
const Home = resolve => require(['@/views/home/index'], resolve);
const Sec = resolve => require(['@/views/home/sec'], resolve);
const Ens = resolve => require(['@/views/home/ens'], resolve);

//新闻资讯
const NewsList = resolve => require(['@/views/news/news_list'], resolve);
const NewsDetail = resolve => require(['@/views/news/news_detail'], resolve);

//招贤纳士
const RecruitIndex = resolve => require(['@/views/recruit/index'], resolve);
const RecruitTrain = resolve => require(['@/views/recruit/train'], resolve);
const RecruitList = resolve => require(['@/views/recruit/list'], resolve);

export default new Router({
  mode: 'history',
  routes: [
    // { //测试页面
    //   path: '/test',
    //   name: 'Test',
    //   component: Test,
    // },
    {
      path: '*',
      redirect: '/' //重定向
    },
    { //首页
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        navIdx: 0
      }
    },
    { //首页-英文
      path: '/ens',
      name: 'Ens',
      component: Ens,
      meta: {
        navIdx: 0
      }
    },
    { //二级页面
      path: '/sec',
      name: 'Sec',
      component: Sec
    },
    { //新闻资讯
      path: '/news_list/:type',
      name: 'NewsList',
      component: NewsList,
      meta: {
        navIdx: 4
      }
    },
    { //新闻资讯
      path: '/news_detail',
      name: 'NewsDetail',
      component: NewsDetail,
      meta: {
        navIdx: 4
      }
    },
    { //招贤纳士
      path: '/recruit',
      name: 'Recruit',
      component: RecruitIndex,
      meta: {
        navIdx: 5
      },
      children:[{
        path: '/recruit/train/:type',
        name: 'RecruitTrain',
        component: RecruitTrain,
        meta: { navIdx: 5 }
      },{
        path: '/recruit/list/:type',
        name: 'RecruitList',
        component: RecruitList,
        meta: { navIdx: 5 }
      }]
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    // console.log(to)
    // console.log(from)
    if (from.meta.noScroll && to.meta.noScroll) return false;
    if(to.meta.noScroll) return false;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    return {
      x: 0,
      y: 0
    }
  },
})
