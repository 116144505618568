<template>
<div class="header-warp">
  <div class="header2">
    <button class="header-icon" @click="navShow=!navShow;sonIdx=null" :class="{close:navShow}"><em></em></button>
    <h1>弘金地体育</h1>
    <ul class="drop-list plr-22" :class="{show:navShow}" @click.self="navShow=!navShow">
      <li v-for="(item,idx) in navList" :key="idx" v-if="idx<3" :class="{arrow:item.children.length!=0}">
        <p @click="toFirstDetail(idx,item)">{{item.categoryName}}</p>
        <div class="son-warp" v-if="item.children.length!=0" :class="{show:sonIdx===idx}">
          <p class="plr-22" @click="sonIdx=null">返回</p>
          <ul class="son-ul plr-22">
            <li v-for="(ele,idxs) in item.children" :key="idxs" @click="toSecDetail(item,ele)">{{ele.categoryName}}</li>
          </ul>
        </div>
      </li>
      <li class="arrow">
        <p @click="toFirstDetail(navList.length,-1)">新闻资讯</p>
        <div class="son-warp" :class="{show:sonIdx===navList.length}">
          <p class="plr-22" @click="sonIdx=null">返回</p>
          <ul class="son-ul plr-22">
            <li @click="toSecDetail('/news_list/company',-1,true)">公司新闻</li>
            <li @click="toSecDetail('/news_list/business',-1,true)">媒体报导</li>
          </ul>
        </div>
      </li>
      <li class="arrow">
        <p @click="toFirstDetail(navList.length+1,-1)">招贤纳士</p>
        <div class="son-warp" :class="{show:sonIdx===navList.length+1}">
          <p class="plr-22" @click="sonIdx=null">返回</p>
          <ul class="son-ul plr-22">
            <li @click="toSecDetail('/recruit/train/train',-1,true)">人才培养</li>
            <li @click="toSecDetail('/recruit/list/school',-1,true)">校园招聘</li>
            <li @click="toSecDetail('/recruit/list/social',-1,true)">社会招聘</li>
          </ul>
        </div>
      </li>
      <li v-for="(item,idx) in navList" :key="idx" v-if="idx===navList.length-1" :class="{arrow:item.children.length!=0}">
        <p @click="toFirstDetail(navList.length+3,item)">{{item.categoryName}}</p>
        <div class="son-warp" v-if="item.children.length!=0" :class="{show:sonIdx===navList.length+3}">
          <p class="plr-22" @click="sonIdx=null">返回</p>
          <ul class="son-ul plr-22">
            <li v-for="(ele,idxs) in item.children" :key="idxs" @click="toSecDetail(item,ele)">{{ele.categoryName}}</li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
  <div class="w-1200 header clear">
    <router-link class="left" tag="h1" to="/">弘金地体育</router-link>
    <ul class="nav-list right">
      <li v-for="(item,idx) in navList" v-if="idx<3"
          :class="{active:hoverIdx===idx}"
          :key="idx" @mouseenter.stop="hoverIdx=idx" @mouseleave="hoverIdx=null">
        <router-link v-if="idx===0" tag="p" to="/home">{{item.categoryName}}</router-link>
        <p v-else>{{item.categoryName}}</p>
        <ul class="son-ul" v-if="item.children.length!=0&&hoverIdx!=null"
            :class="{'marginLeft101':item.children.length>4}">
          <router-link tag="li" v-for="(ele,idxs) in item.children" :key="idxs"
                       :to="'/sec?fid='+item.categoryId+'&cid='+ele.categoryId">{{ele.categoryName}}</router-link>
        </ul>
      </li>
      <li @mouseenter.stop="hoverIdx=navList.length" :class="{active:hoverIdx===navList.length}"
          @mouseleave="hoverIdx=null">
        <router-link tag="p" to="/news_list/company">新闻资讯</router-link>
        <ul class="son-ul">
          <router-link tag="li" to="/news_list/company"> 公司新闻 </router-link>
          <router-link tag="li" to="/news_list/business"> 媒体报导 </router-link>
        </ul>
      </li>
      <li @mouseenter.stop="hoverIdx=navList.length+1"  :class="{active:hoverIdx===navList.length+1}"
          @mouseleave="hoverIdx=null">
        <router-link tag="p" to="/recruit/train/train">招贤纳士</router-link>
        <ul class="son-ul marginLeft101">
          <router-link tag="li" to="/recruit/train/train"> 人才培养 </router-link>
          <router-link tag="li" to="/recruit/list/school"> 校园招聘 </router-link>
          <router-link tag="li" to="/recruit/list/social"> 社会招聘 </router-link>
        </ul>
      </li>
      <li v-for="(item,idx) in navList" v-if="idx==3"
          :class="{active:hoverIdx===navList.length+2}"
          :key="idx" @mouseenter.stop="hoverIdx=navList.length+2" @mouseleave="hoverIdx=null">
        <router-link v-if="idx===0" tag="p" to="/home">{{item.categoryName}}</router-link>
        <p v-else>{{item.categoryName}}</p>
        <ul class="son-ul" v-if="item.children.length!=0&&hoverIdx!=null"
            :class="{'marginLeft101':item.children.length>4}">
          <router-link tag="li" v-for="(ele,idxs) in item.children" :key="idxs"
                       :to="'/sec?fid='+item.categoryId+'&cid='+ele.categoryId">{{ele.categoryName}}</router-link>
        </ul>
      </li>
    </ul>
    <a class="search-box" href="/ens" target="_blank">
      <span>中/English</span>
    </a>
  </div>
</div>
</template>

<script>
import {
  NavList
} from '@/api/index.js'

export default {
  data() {
    return {
      onSearch: false,
      bgLeft: -1000,
      hoverIdx: null,
      navIdx: 0,
      navList: [],
      navShow: false,
			sonIdx:null
    }
  },
  watch: {
    hoverIdx(newVal) {
      if (newVal === null) {
        this.hoverIdx = this.navIdx
        return false
      }
      // this.bgLeft = newVal * 101 + 22;
    },
    $route() {
      this.activeIdxGet()
    }
  },
  created() {
    this.getData()
    this.navIdx = this.$route.meta.navIdx
    // this.hoverIdx = this.navIdx
    this.activeIdxGet()
  },
  methods: {
    activeIdxGet(){
      if (this.$route.name == 'Home') {
        this.navIdx = 0;
      } else if (this.$route.name == 'NewsList' || this.$route.name == 'NewsDetail') {
        this.navIdx = 4;
      } else if (this.$route.name == 'RecruitTrain' || this.$route.name == 'RecruitList') {
        this.navIdx = 5;
      } else {
        this.navIdx = this.$route.query.fid - 1;
      }
      this.hoverIdx = this.navIdx
    },
    getData() {
      NavList().then(res => {
        if (res) {
          // console.log(res.rows)
          this.navList = res.rows
        }
      })
    },
    tipPop() {
      (this.GLOBAL.warningPop)(this, '正在建设中，敬请期待。')
    },
    enterAction(idx) {
      this.hoverIdx = idx
    },
    leaveAction(idx) {
      if (idx != this.navIdx) {
        this.hoverIdx = this.navIdx
      }
    },
		toFirstDetail(idx,item){
			if(item.categoryId==1){
				this.$router.push('/home');
				this.navShow=false;
			}else if(item.categoryId==5){
				this.$router.push('/news_list');
				this.navShow=false;
			}else{
				this.sonIdx=idx;
			}
		},
		toSecDetail(item,ele,self=false){
      if(self){
        this.$router.push(item)
      }else{
        this.$router.push('/sec?fid='+item.categoryId+'&cid='+ele.categoryId)
      }
			this.navShow=false;
			this.sonIdx=null;
		}
  }
}
</script>

<style lang="less" scoped>
@import '../assets/css/params.less';
@media screen and (min-width: 1024px) {
    .header {
        display: block;
    }
    .header2 {
        display: none;
    }
    .header-warp {
        width: 100%;
        height: 75px;
        background-color: #fff;
    }
}
@media screen and (max-width: 1024px) {
    .header {
        display: none;
    }
    .header2 {
        display: block;
    }
    .header-warp {
        width: 100%;
        height: 50px;
        background-color: #fff;
				position: fixed;
				top:0;
				left:0;
				z-index: 99;
    }
}
.header2 {
    position: relative;
    height: 100%;
    h1 {
        text-indent: -999em;
        width: 100%;
        height: 100%;
				background-color: #fff;
        background-image: url("../../static/img/iphone/logo.png");
        background-size: auto 32px;
        background-position: center;
        background-repeat: no-repeat;
				z-index: 10;
				position: absolute;
    }
    .header-icon {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 50px;
        height: 50px;
				z-index: 11;
				background-color: #fff;
        &::after,
        &::before,em {
            content: "";
            display: block;
            width: 50%;
            height: 3px;
            position: absolute;
            left: 25%;
            background-color: #333;
						transition: all .3s;
        }
        &::after {
            bottom: 14px;
        }
        &::before {
            top: 14px;
        }
				em{
					margin-top: -2px;
				}
    }
    .header-icon.close {
				em{
					display: none;
				}
        &::after,
        &::before {
        	  top: 50%;
            background-color: @main1;
        }
        &::after {
            transform: rotate(45deg);
        }
        &::before {
            transform: rotate(-45deg);
        }
    }
			>.drop-list{
				position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height:0;
				padding-top: 0;
        background-color: #fff;
        z-index: 9;
				color: #231815;
				.box;
				line-height: 48px;
				transition: all .5s;
				overflow: hidden;
				&.show{
					padding-top: 50px;
					height: 100%;
				}
				li{
					display: block;
					width: 100%;
					height: 48px;
					border-bottom: 1px solid #f2f2f2;
					&.arrow{
						position: relative;
						&::after{
							content: "";
							display: block;
							background-image:url('../assets/img/2021/right.png');
							background-size: 100%;
							width: 8px;
							height:14px;
							position: absolute;
							right:0;
							top:50%;
							margin-top: -4px;
						}
					}
					p{
						font-size: 20px;
					}
					>.son-warp{
						>p{
							background-color: #f2f2f2;
							font-size: 20px;
						}
					}
					>.son-warp{
						position: fixed;
		        top: 0;
		        left: 100%;
		        width: 100%;
						height:0;
						background-color: #fff;
						.box;
						padding-top: 50px;
						color: #231815;
						z-index: 100;
						transition: all .3s;
						overflow: hidden;
						opacity: 0;
						&.show{
							opacity: 1;
							height:100%;
							left:0;
						}
						>ul{
							// height: calc(100vh - 98px);
							font-size: 20px;
							background-color: #fff;
						}
					}
				}
			}
}
.header {
    height: 100%;
    position: relative;
    width: 100%;
    h1 {
        .cus;
        text-indent: -999em;
        width: 130px;
        height: 42px;
        background-image: url("../../static/img/logo.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        margin-top: 16px;
    }
    .search-box {
      cursor: pointer;
        position: absolute;
        right: -90px;
        height: 100%;
        width: 110px;
        padding-top: 28px;
        font-size: 14px;
        line-height: 22px;
        input {
            border-radius: 5px;
            border: 1px solid #999999;
            height: 20px;
            width: 110px;
            padding: 10px;
            float: left;
            box-sizing: border-box;
        }
        i {
            cursor: pointer;
            float: left;
            margin: 0 10px;
        }
        span {
            float: left;
        }
    }
    .nav-list {
        text-align: center;
        width: 707px;
        height: 75px;
        position: relative;
        &:hover {
            li.tag.cur-hide {
                display: block;
            }
        }
        > li {
            height: 75px;
            width: 101px;
            .cus;
            &.tag {
                position: absolute;
                height: 3px;
                width: 56px;
                background-color: @main1;
                bottom: 3px;
                transition: left 0.4s ease-in-out;
                &.cur-hide {
                    display: none;
                }
            }
            > p {
                line-height: 75px;
                font-size: 16px;
            }
            &:hover,&.active {
                background-color: #EA5532;
                color: #fff;
              transition: all .3s;
            }
            &:hover .son-ul {
              height: 54px;
            }
            > .son-ul {
                overflow:hidden;
                position: absolute;
                width: 200%;
                height: 0;
                z-index: 4;
                font-size: 16px;
                transition: height 0.2s ease-in-out;
                background: rgba(170, 170, 170, 0.4);
                text-align: left;
                &.marginLeft101{
                  margin-left: -90px;
                }
                li {
                    line-height: 54px;
                    color: #fff;
                    height: 54px;
                    padding: 0 15px;
                    //&:hover {
                    //    color: #ea5532;
                    //}
                }
            }
        }
    }
}
</style>
