<template>
  <div id="app">
    <div class="video-warp" v-if="!played">
      <video
          style="width: 100%;height:100vh;"
          id="openVideo"
          muted autoplay="autoplay"
          preload="auto"
          x-webkit-airplay="allow"
          x5-video-player-type="h5"
          x5-video-player-fullscreen="true"
          x5-playsinline="true"
          playsinline="true"
          webkit-playsinline="true">
        <source src="https://gemdale-sports.oss-cn-shenzhen.aliyuncs.com/2021/08/26/bbdb08d3ff9016817a3b9814d2a43def.mp4"
                type="video/mp4">
      </video>
    </div>
<!--    class="animate__animated animate__bounceInDown" -->
    <div v-else>
      <v-header class="app-header" @toggleLange='toggleLange'></v-header>
      <img v-show='!navHide' src='../static/img/t_banner.png' class="t-banner">
      <router-view class="app-con" ref="routerchild" @hideHeader='hideHeader' ></router-view>
      <v-footer></v-footer>
    </div>
  </div>

</template>

<script>
import VHeader from "@/components/header";
import VFooter from "@/components/footer";

export default {
  name: "App",
  components: {
    VHeader, VFooter
  },
  data() {
    return {
      navHide: true,
      bgPadding: 0,
      played:false,
      isPc:false,
    };
  },
  watch:{
    $route(){
      let name=this.$route.name
      if(name==='Ens'||name==='Home'){
        this.navHide=true
      }else{
        this.navHide=false
      }
    }
  },
  beforeMount() {
    this.isPc=this.IsPC()
    if(!this.isPc) {
      this.played = true
    }else if(sessionStorage.getItem('HomeVideoPlayed')=='true'){
      this.played=true
    }
  },
  mounted() {
    // this.handleScroll();
    // window.addEventListener("scroll", this.handleScroll);
    if(this.isPc&&!this.played){
      let _this=this
      let elevideo = document.getElementById("openVideo");
      elevideo.addEventListener('ended', function () { //结束
        _this.played=true
        sessionStorage.setItem('HomeVideoPlayed',true)
      }, false);
    }
  },
  methods: {
    IsPC() {
      let userAgentInfo = navigator.userAgent;
      let Agents = ["Android", "iPhone",
        "SymbianOS", "Windows Phone",
        "iPad", "iPod"
      ];
      let flag = true;
      for (let v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    handleScroll() {
      var scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
      if (scrollTop < 75) {
        this.bgPadding = 75 - scrollTop;
      } else {
        this.bgPadding = 0;
      }
      // console.log(scrollTop)
    },
    toggleLange() {
      //切换语言，重新请求接口数据
      // this.$refs.routerchild.init();
    },
    hideHeader(flag) {
      // console.log('hideHeader='+flag)
      this.navHide = flag;
    }
  }
};
</script>

<style lang='less'>
// #app{
// 	padding-top: 75px;
// }
.video-warp{
  width: 100%;
  height: 100vh;
  overflow:hidden ;
  video{
    object-fit: cover;
  }
}
.t-banner {
  height: 300px;
  object-fit: cover;
}
.app-con {
  min-height: calc(100vh - 380px);
  box-sizing: border-box;
}
.bg-fff {
  // background-image: url(../../assets/img/g_bg.png);
  // background-size:100% 737px;
  // overflow-y: auto;
  // background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  img {
    object-fit: cover;
  }
  // padding-top: 255px;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}
#ts-img .roll-list-warp > button.next {
  background-image: url("./assets/img/f_arrow_right.png");
}
#ts-img .roll-list-warp > button.prev {
  background-image: url("./assets/img/f_arrow_left.png");
}
@media screen and (max-width: 1024px) {
  .t-banner {
    margin-top: 50px;
    display: none !important;
  }
  .app-con {
    padding-top: 50px;
    min-height: calc(100vh - 130px);
  }
  .bg-fff {
    display: none;
  }
}

#app .vjs-big-play-button{
  width: 74px;
  height: 74px;
  border-radius: 50%;
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -37px;
  margin-top: -37px;
  span{
    line-height: 74px;
  }
}
@media screen and (max-width: 1024px) {
  #app .vjs-big-play-button{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: none;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    margin-top: -12px;
    span{
      line-height: 24px;
      font-size: 20px;
    }
  }
}
#app {
  .el-input__inner, .el-input-group__append {
    border-radius: 0;
  }
  .el-input-group__append {
    padding: 0 14px;
    background: #eeeeee;
  }
  .el-select{
    width: 100%;
  }
  .el-input__suffix{
    right: 0;
    width: 45px;
    background: #eeeeee;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    .el-select__caret el-input__icon el-icon-arrow-up{
      font-size: 20px;
    }
  }
}
.el-scrollbar li{
  display: block;
}
.pp-bar{
  width: 350px;
  height: 2px;
  background-color: #fff;
  display: inline-block;
  position: relative;
  margin-top: 8px;
  &:after{
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: -5px;
    background-color: #ffffff;
  }
  .active-bar{
    height: 100%;
    background: #bfbfbf;
  }
}
</style>
