import Vue from 'vue'
import App from './App.vue'
import router from './router'
Vue.config.productionTip = false
import './assets/css/reset.css';
//修改网页头部meta信息
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

import ElementUI from 'element-ui';
import './assets/element-#EA5532/index.css';
Vue.use(ElementUI);



import global_ from './assets/js/Global'
Vue.prototype.GLOBAL = global_;

import animated from 'animate.css'
Vue.use(animated)

import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
Vue.use(VueVideoPlayer)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
