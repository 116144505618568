import {
  Message
} from 'element-ui'

//全局变量
function COMMON() {
  return {
    img: ''
    // img: 'http://10.131.13.233:9070'
    // img:'http://www.gemdalesports.com'
  }
}

//警告弹出框
function warningPop(_this, txt) {
  Message({
    type: 'warning',
    message: txt
  });
}

// //成功弹出框
function successPop(_this, txt) {
  Message({
    type: 'success',
    message: txt
  });
}

export default {
  warningPop,
  COMMON,
}
