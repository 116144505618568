import fetch from './fetch.js'
function IsPC() {
  let userAgentInfo = navigator.userAgent;
  let Agents = ["Android", "iPhone",
    "SymbianOS", "Windows Phone",
    "iPad", "iPod"
  ];
  let flag = true;
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}
function makeFormData(obj) { //普通对象转成formData
  let form_data = new FormData();
  for (let keys in obj) {
    form_data.append(keys, obj[keys])
  }
  return form_data
}
//导航栏
export function NavList() {
  let uri = '/category/list';
  return fetch({
    url: uri,
    method: 'post'
  });
}
//二级页面
export function SecContent(id) {
  let c=2
  if(IsPC()) c=1
  let uri = '/category/'+id+"?client="+c;
  return fetch({
    url: uri,
    method: 'get'
  });
}
//新闻列表
export function newsList(params) {
  let uri = '/news/list';
  return fetch({
    url: uri,
    method: 'post',
    data: makeFormData(params)
  });
}
export function PsNewsList(params) {
  let uri = '/openMatch/list';
  return fetch({
    url: uri,
    method: 'post',
    data: makeFormData({
      'pageSize':params.size,
      'pageNum':params.num
    })
  });
}
//新闻列表
export function newsNext(action,curId,model) {
  let uri = '/news/next';
  return fetch({
    url: uri,
    method: 'post',
    params: {
      'model':model,
      'action':action,
      'articleId':curId
    }
  });
}
//新闻列表
export function newsDetail(id) {
  let uri = '/news/'+id;
  return fetch({
    url: uri,
    method: 'post'
  });
}
//轮播图
export function indexContent(type) {
  let uri = '/entry/list?type='+type;
  if(type==1){
    if(IsPC()){
      uri +="&client=1"
    }else{
      uri +="&client=2"
    }
  }else{
    uri +="&client=1"
  }
  return fetch({
    url: uri,
    method: 'post',
    // data: makeFormData({
    //   "type":type
    // })
  });
}

//招聘列表
export function RecruitList(params) {
  let uri = '/recruitment/list';
  console.log(params)
  return fetch({
    url: uri,
    method: 'post',
    data: makeFormData(params)
  });
}

//最新活动
export function NnList() {
  let uri = '/newestArticle/list';
  return fetch({
    url: uri,
    method: 'post'
  });
}

//最新活动
export function HomeNews(params) {
  let uri = '/news/hotNewsList';
  return fetch({
    url: uri,
    method: 'post',
    data:makeFormData(params)
  });
}

//友情链接
export function BlogList() {
  let uri = '/blogrollApi/list';
  return fetch({
    url: uri,
    method: 'post'
  });
}
