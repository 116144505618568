<template>
<div class="footer">
  <div class="w-1200">
    <div class="left-left" v-if="leftList.length>0">
      <img class="logo" src="../assets/img/2021/footer_logo.png" alt="">
      <div class="label">
        友情链接
        <i class="icon-down"></i>
        <ul class="left-list">
          <li class="left-item" v-for="(item,idx) in leftList" :key="idx">
            <a :href="item.adjustLink" target="_blank">{{item.linkName}}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="left">
      <h4 class="phone">
        电话：<em>0755-83157766</em>
      </h4>
      <p class="addr">深圳市福田区香蜜湖路4001号（近侨香路，金地网球中心旁） </p>
      <p class="addr">深圳市弘金地体育集团有限公司 <a href="https://beian.miit.gov.cn" style="color:#6e6e6e;">粤ICP备14029242号</a></p>
    </div>
    <div class="right">
      <span>关注弘金地</span>
      <div class="qr-box">
        <img src="../assets/img/2021/footer_tx.png" alt="">
        <div class="qr">
          <img clss src="../assets/img/2021/footer_qr_tx.jpg" alt="">
        </div>
      </div>
      <div class="qr-box">
        <img src="../assets/img/2021/footer_douyin.png" alt="">
        <div class="qr">
          <img clss src="../assets/img/2021/footer_qr_douyin.jpg" alt="">
        </div>
      </div>
      <div class="qr-box weibo">
        <img src="../assets/img/2021/footer_weibo.png" alt="">
        <div class="qr">
          <img src="../assets/img/2021/footer_qr_wechat.jpg" alt="">
        </div>
      </div>
      <div class="qr-box wechat">
        <img src="../assets/img/2021/footer_wechat.png" alt="">
        <div class="qr">
          <img clss src="../assets/img/2021/footer_qr_weibo.png" alt="">
        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>

  import {BlogList} from "@/api";

  export default {
    data(){
      return {
        leftList:[]
      };
    },
    created(){
      this.getBlogList()
    },
    methods : {
      tipPop(){
        (this.GLOBAL.warningPop)(this, '正在建设中，敬请期待。');
      },
      getBlogList(){
        BlogList().then(res=>{
          this.leftList=res.rows
        })
      },
    }
  };
</script>

<style lang="less" scoped>
  @import '../assets/css/params.less';
  .footer{
    background: #eeeeee;
  }
  @media screen and (max-width: 1024px) {
    .footer .left-left{
      display: none;
    }
  }
  .footer>div{
    height: 166px;
    display: flex;
    .left-left{
      width: 150px;
      padding-right: 20px;
      text-align: center;

      .logo{
        width: 88px;
        margin: 28px auto ;
      }
      .label{
        position: absolute;
        width: 150px;
        z-index: 2;
        height: 42px;
        line-height: 42px;
        background: #646464;
        border-radius: 5px;
        font-size: 14px;
        color: #fff;
        overflow: hidden;
        &:hover{
          overflow: inherit;
          .left-list{
            bottom: 42px;
            opacity: 1;
            z-index: 1;
          }
        }
        .icon-down{
          vertical-align: middle;
          display: inline-block;
          width: 17px;
          height: 14px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          margin-left: 5px;
          background-image: url("../assets/img/2021/footer_down.png");
        }

        .left-list{
          z-index: -1;
          height: auto;
          overflow: hidden;
          position: absolute;
          width: 150px;
          left: 0;
          bottom: -30px;
          line-height: 30px;
          color: #646464;
          background-color: #e6e6e6;
          transition: all .3s;
          opacity: 0;

          .left-item{
            height: 30px;
            width: 100%;
            transition: all .3s;
            &:hover {
              background-color: #ea5431;
              a {
                color: #fff;
              }
            }

          }
        }

      }

    }
    .left{
      flex: 1;
      padding-top: 26px;
      .phone{
        padding-left: 44px;
        height: 34px;
        line-height: 34px;
        color: #EA5532;
        background-image: url("../assets/img/2021/footer_phone.png");
        background-size: 34px;
        background-position: center left;
        background-repeat: no-repeat;
        margin-bottom: 20px;
        em{
          font-weight: bold;
          font-style: normal;
        }
      }
      p{
        color: #6e6e6e;
        line-height: 26px;
        font-size: 14px;
      }
    }
    .right{
      width: 340px;
      line-height:47px;
      padding-top: 52px;
      .qr-box{
        width: 47px;
        height: 47px;
        display: inline-block;
        float: right;
        margin-right: 20px;
        position: relative;
        >img{
          z-index: 2;
        }
        &:hover {
          .qr {
            top: -96px;
            opacity: 1;
          }
        }
        .qr{
          width: 84px;
          height: 84px;
          background: #ea5532;
          position: absolute;
          top: 0;
          left: -22px;
          transition: all .3s;
          opacity: 0;
          >img{
            width: 76px;
            height: 76px;
            margin: 3px auto;
          }
          &:after{
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 6px solid #ea5532;
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -2px;
          }
        }
      }
      span{
        display: block;
        float: right;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .footer{
      padding:15px 20px;
      >div{
        height: auto;
        .left{
          padding-top: 0;
          .phone{
            background-size: 17px;
            font-size: 10px;
            padding-left: 20px;
            margin-bottom: 0px;
          }
          p{
            font-size: 10px;
            line-height: 1.5;
          }
        }
        .right{
          padding-top: 0;
          width: 70px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          >span{
            display: none;
          }
          .qr-box{
            width: 24px;
            height: 24px;
            margin-right: 0;
            display: inline-block;
            float: inherit;
            vertical-align: middle;
            .qr{
              width: 55px;
              height: 55px;
              top: -63px!important;
              left: -17px!important;
              &:after{
                margin-left: -4px;
              }
              >img{
                width: 51px;
                height: 51px;
                margin:2px auto;
              }
            }
          }
        }
      }
    }
  }
</style>
